import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export default function DefaultResponse(props: any) {
  return <Message {...props} />
}

const Message = styled((props: any) => <Typography variant="h5" {...props} />)`
  display: block;
  margin-bottom: 10px;
  margin-top: 0;
  line-height: 1.2;
  text-align: center;
`
