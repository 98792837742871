// Generously borrowed from https://stackoverflow.com/a/61000836
import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

/**
 * Identical to React.useEffect, except that it never runs on mount. This is
 * the equivalent of the componentDidUpdate lifecycle function.
 *
 * @param {function:function} effect - A useEffect effect.
 * @param {array} [dependencies] - useEffect dependency list.
 */
export default function useEffectExceptOnMount(
  effect: EffectCallback,
  dependencies: DependencyList | undefined
) {
  const mounted = useRef(false)
  useEffect(() => {
    if (mounted.current) {
      const unmount = effect()
      return () => unmount && unmount()
    } else {
      mounted.current = true
    }
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps

  // Reset on unmount for the next mount.
  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])
}
