import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { constants, screenSize } from '../tokens'

const LoadMoreSection = styled.div`
  padding: ${constants.globalBufferSmall};
  padding-bottom: 0;

  ${screenSize.large(css`
    width: 240px;
    margin: 0 auto !important;
  `)}
`

export default LoadMoreSection
